import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Blog', href: '/blog' },
  { name: 'Contact', href: '/contact' },
]

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default function Header() {
  return (
    <header>
      <Popover as="header" className="relative">
        {({ open }) => (
          <>
            <div className="bg-white">
              <nav
                className="relative max-w-7xl mx-auto px-4 py-6 sm:px-6 md:space-x-10 lg:px-8 flex items-center justify-between pt-6 xl:px-8"
                aria-label="Global"
              >
                <div className="flex items-center flex-1">
                  <div className="flex items-center justify-between w-full lg:w-auto">
                    <Link to="/">
                      <span className="sr-only">Home</span>
                      <StaticImage
                        src="../images/icon.png"
                        placeholder="blurred"
                        alt="Techeria logo"
                        width={40}
                        height={40}
                        className="h-8 w-auto"
                      />
                    </Link>
                    <div className="-mr-2 flex items-center lg:hidden">
                      <Popover.Button className="bg-warm-gray-50 rounded-md p-2 inline-flex items-center justify-center text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-teal-500">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="hidden space-x-10 lg:flex lg:ml-10">
                    {navigation.map(item => (
                      <Link
                        to={item.href}
                        key={item.name}
                        className="text-base font-medium text-warm-gray-500 hover:text-warm-gray-900"
                        activeClassName="text-warm-gray-900"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </nav>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top lg:hidden"
              >
                <div className="rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <StaticImage
                        src="../images/icon.png"
                        placeholder="blurred"
                        alt="Techeria logo"
                        width={40}
                        height={40}
                        className="h-8 w-auto"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="pt-5 pb-6">
                    <div className="px-2 space-y-1">
                      {navigation.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block px-3 py-2 rounded-md text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  )
}
